import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { MDText } from 'i18n-react';

import { LoadingSpinner } from '@motorway/motorway-storybook-cra';
import { Hyperlink } from '@motorway/mw-highway-code/src/components/Hyperlink';

import { ConfigContext } from 'Context/config';

import { useQuery, useRedirectToAccountIfUser } from 'Utilities/hooks';

import { ComponentContent, ComponentsWrapper, CONTENT_SIZES } from 'Layout';

import Button from 'Button';

import LocalTexts from './TokenExpiredTexts.json';

import styles from './TokenExpired.scss';

const LocalT = new MDText(LocalTexts);

const TokenExpired = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  // @ts-ignore PIR-705 fix types for feature flag import
  const { configState: { featureFlags: { showNewTokenExpiredMessage } } } = useContext(ConfigContext);
  const isRedirectFromUnsubscribe = useQuery('redirect')?.startsWith('unsubscribe');

  const email = 'privacy@motorway.co.uk';

  useEffect(() => {
    setLoading(false);
  }, []);

  useRedirectToAccountIfUser();

  if (loading) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    // @ts-ignore
    <ComponentsWrapper className={styles.component}>
      { showNewTokenExpiredMessage && isRedirectFromUnsubscribe
        ? (
          <ComponentContent className='' maxWidth={CONTENT_SIZES.big}>
            <h2>{LocalT.translate('tokenExpiredView.unsubscribe.title')}</h2>
            <p>{LocalT.translate('tokenExpiredView.unsubscribe.detail', {
              email: <Hyperlink href={`mailto:${email}`} label={email} />,
            })}</p>
          </ComponentContent>
        )
        : (
          <>
            <ComponentContent className='' maxWidth={CONTENT_SIZES.big}>
              <h2>{LocalT.translate('tokenExpiredView.title')}</h2>
              <p>{LocalT.translate('tokenExpiredView.detail')}</p>
            </ComponentContent>
            <ComponentContent className='' maxWidth={CONTENT_SIZES.small}>
              <Button
                className={styles.button}
                onClick={() => history.push(`/sign-in${search}`)}
                rightIcon="chevron-right"
              >
                {LocalT.translate('tokenExpiredView.buttonLabel')}
              </Button>
            </ComponentContent>
          </>
        )
      }
    </ComponentsWrapper>
  );
};

export default TokenExpired;
